<template>
  <div id="app1">
     <div style="padding:1rem;background:#FFF">
      <div class="topBox">
        <div class="W_80">
          <div class="fontS_20 font_weight_bold text_overflow_1"> {{companyName}}</div>
          <div class="flex MT_2" >
             <img style="width:2rem;height:2rem;"   src="../assets/xiaoren-icon@2x.png" alt="">
            <span class="ML_2">{{companyMan}}</span>
          </div>
        </div>
        <div>
          <img v-if="companyAvatar" style="width:3rem;height:3rem;border-radius:50%" :src="companyAvatar" alt="">
          <img v-else style="width:3rem;height:3rem;border-radius:50%" src="../assets/nologo@2x.png" alt="">
        </div>
      </div>
      <div  >
        <div class="searchBtn">
        <van-icon size="30" name="search" />
         <van-field
          @keyup.enter.native="getList"
            v-model="company"
            placeholder="输入企业信息"
          />
      </div>
      </div>
      
      <van-swipe class="my-swipe"  :autoplay="3000" indicator-color="white" >
        <van-swipe-item>
          <img src="../assets/banner@2x.png" alt="">
        </van-swipe-item>
      </van-swipe>
      <div class="title MTB_4" >企业报告类型</div>
      <div class="flexList">
        <div class="flexItem">
          <img src="../assets/touhou-icon@2x.png" alt="">
          <span>季度投后报告</span> 
        </div>
        <div class="flexItem">
          <img src="../assets/renli-icon@2x.png" alt="">
          <span>季度人力资源报告</span> 
        </div>
      </div>
      <div class="titleList">
        <div class="title">企业信息</div>
        <div style="color:#6F81A3" @click="gocompanyList">更多信息</div>
      </div>
     </div>

     <div class="companyList" v-if="list.length > 0">
        <div class="companyItem MB_5" @click="goDetail(item)" v-for="(item,index) in list" :key="index">
          <div class="companyTop">
            <div class="MR_1"  style="width:4rem;border-radius:50%">
              <img style="width:100%"  src="../assets/nologo@2x.png" alt="">
            </div>
            <div class="W_80">
              <div class=" fontS_20 font_weight_bold text_overflow_1">{{item.company}}</div>
              <div class="companyScore MT_1">
                <div>企业力度分：{{item.sum_score == '暂无' ? item.sum_score  : Number(item.sum_score).toFixed(0)}}</div>
                <div class="ML_5">企业人数 {{item.number}}</div>
              </div>
            </div>
          </div>
          <div class="companyModel">
            <div class="companyModel1">
              <img  src="../assets/sanren-icon@2x.png" alt="">
              <span>企业人力资源服务</span>
              <span style="margin-left:0.7rem;color: #96A6C4;">服务未激活</span>
            </div>
            <div class="companyModel1 MT_1" >
              <img  src="../assets/zonghe-icon@2x.png" alt="">
              <span>企业季度综合统计服务</span>
              <span style="margin-left:0.7rem;color: #96A6C4;">服务已激活</span>
            </div>
          </div>
          <div class="companyBottom MT_3">
            <div>
              <span>查看企业季度报告</span>
              <van-icon size="15" color="#3943BC" name="arrow" />
            </div>
            <div style="color: #6F81A3;" v-if="item.day != '暂无'" >{{item.day}}天前更新</div>
            <div style="color: #6F81A3;" v-else > </div>
          </div>
        </div>
     </div>
     <van-empty v-else description="暂无公司" />
  </div>
</template>
<script>
import {mapState,mapActions} from "vuex"
export default {
  data(){
    return {
      list:[],
      company:""
    }
  },
  computed: {
    ...mapState({
      userInfo:  (state) => state.userInfo,
      companyName: (state) => state.companyName,
      companyAvatar: (state) => state.companyAvatar,
      companyMan: (state) => state.companyMan,
    }),
  },
  methods:{
    ...mapActions(["bindList"]),
    gocompanyList(){
      this.$router.push("/myCompany")
    },
    goDetail(item){
      this.$store.commit("SET_REPORT_ITEM",item)
      this.$router.push({path: '/reportDetail',});
    },
    getList(){
      this.bindList({
        reqType: "login", //请求类型
        id: this.userInfo.id, //企业信息id
        pageNo: 1,
        limit: 100,
        company: this.company //公司
        // this.userInfo.enterprise_name
      }).then((res)=>{
       let list1 = res.data.companyList.map((item)=>{
          if(item.add_time != "暂无"){
           let a =  item.add_time.split(" ")[0]

            let date = new Date()
            var year = date.getFullYear(); 
            var month = date.getMonth() + 1;
            var day = date.getDate();
            month = (month > 9) ? month : ("0" + month);
            day = (day < 10) ? ("0" + day) : day;
            var today = year + "-" + month + "-" + day
            let b = this.getDay(a,today)
            console.log(b)
            item.day = b
          } else {
            item.day = "暂无"
          }
           
          if(item.sum_score != "暂无"){
            item.sum_score = Number(item.sum_score).toFixed(1)
          }
          return item
        })
        console.log(list1)
        this.list = list1
      })
    },
    toTimeStamp(dateString){
      // dateString例如:'2022-03-05'
      // 例如返回:1646611200000
      return new Date(dateString) - 0
    },
    getDay(date1,date2){
       
      // date1例如:'2022-03-05',date2例如:'2022-03-06'
      const date1_timeStamp = this.toTimeStamp(date1)
      const date2_timeStamp = this.toTimeStamp(date2)
      let max = '', min = ''
      if(date1_timeStamp>date2_timeStamp){
        max = date1_timeStamp
        min = date2_timeStamp
      }else{
        max = date2_timeStamp
        min = date1_timeStamp
      }
    // 例如返回:'1'
    return (max-min)/(24*60*60*1000)
    },
    
  },
  mounted(){
    this.getList()
  },

}
</script>
<style scoped>
.van-cell{
  background-color: transparent;
}
.titleList{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top :1rem;
}
.flexList{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flexItem{
  display: flex;
  align-items: center;
  width:48%;
  background-color:#EDEEFF;
  padding:1.5rem 0.6rem;
  color: #4A5771;
  border-radius: 0.6rem;
}
.flexItem img{
  width:2rem;
  margin-right:0.5rem;
}
.title{
  font-size: 1.3rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
}

/* #app1{
  background-color: #fff;
  height:100vh;
} */
.topBox{
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:#fff;
  padding:1rem 1rem 0 1rem ;
}
.flex{
  display: flex;
  align-items: center;

}
</style>